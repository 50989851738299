<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Area personale</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
        </ion-toolbar>
      </ion-header>  
      <div id="container">
        <ion-grid> 
          <ion-row id="rusername">
            <ion-col id="husername">
              <ion-label> <b>Nome utente:</b> </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col id="username">
              {{ user.name }}                   
            </ion-col>
          </ion-row>
          <ion-row  id="remail">
            <ion-col id="hemail">
              <ion-label id="lemail"> <b>E-Mail:</b> </ion-label>       
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col id="email">
              {{ user.email }}          
            </ion-col>              
          </ion-row>
           <ion-row  id="remail">
            <ion-col id="hemail">
              <ion-label id="lemail"> <b>Livello:</b> </ion-label>       
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col id="email">
              <div v-if="user.role==0">Cliente</div>
              <div v-if="user.role==1">Consulente</div>
              <div v-if="user.role==2">Stagista</div>
              <div v-if="user.role==3">Interinale</div>
              <div v-if="user.role==4">Dipendente</div>
              <div v-if="user.role==5">Admin</div>
              <div v-if="user.role==6">Company admin</div>
              <div v-if="user.role==7">Superadmin</div>
            </ion-col>              
          </ion-row>
          <ion-row  id="rpassword">
            <ion-col id="hpassword">
              <ion-label> <b>Password:</b> </ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-button id="modpwd" value="mod" v-on:click="isHidden = !isHidden"> Modifica </ion-button>
            </ion-col>
          </ion-row>         
          <div id="modificapwd" v-if="isHidden == true" style="display:block;">             
            <ion-row id="rvecchiapassword">        
              <ion-col id="hvecchiapassword">
                <ion-label>Vecchia Password:</ion-label>                
              </ion-col>        
            </ion-row>
            <ion-row>
              <ion-col><ion-input id="vecchiapwd" type="password" v-model="vecchiapwd"></ion-input></ion-col>
            </ion-row>
            <ion-row id="rnuovapassword">        
              <ion-col id="hnuovapassword">
                <ion-label>Nuova Password:</ion-label>                
              </ion-col>
            </ion-row>
            <ion-row>     
              <ion-col><ion-input id="nuovapwd" type="password" v-model="nuovapwd"></ion-input></ion-col>
            </ion-row>
            <ion-row id="rconfermapassword">
              <ion-col id="hconfermapassword">
                <ion-label>Conferma password:</ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-input id="confermapwd" type="password" v-model="confermapwd"></ion-input>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-button id="aggiornapwdbtn" @click="modificaPassword">AGGIORNA</ion-button>
            </ion-row>
          </div>
            <ion-row>
            <h2 style="float:left; margin-top:2%">Personalizza dashboard</h2>
            </ion-row>
          <div style="height:10%;" v-if="isHidden==false">
            <ion-row>
              <ion-col></ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                  <label><b>Timbratore</b></label>
              </ion-col>      
              <ion-col>
                  <label><b>Progr. attività</b></label>
              </ion-col>
            </ion-row>
            <ion-row>           
              <ion-col>
                <ion-radio-group v-model="vediBadge">
                  <label>Si &nbsp;</label>
                  <ion-radio value="1" :disabled="$store.state.userRole>4 || $store.state.userRole<1 ? true : false"></ion-radio>
                  <label> &nbsp; No &nbsp;</label>
                  <ion-radio value="2" :disabled="$store.state.userRole>4 || $store.state.userRole<1 ? true : false"></ion-radio>
                </ion-radio-group>
              </ion-col>      
              <ion-col>
                <ion-radio-group v-model="vediProgAtt">
                  <label>Si &nbsp;</label>
                  <ion-radio value="1" :disabled="$store.state.userRole==0 ? true : false"></ion-radio>
                  <label>&nbsp; No &nbsp; </label>
                  <ion-radio value="2" :disabled="$store.state.userRole==0 ? true : false"></ion-radio>
                </ion-radio-group>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                  <label><b>Report attività</b></label>
              </ion-col>
              <ion-col>
                  <label><b>Report permessi</b></label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-radio-group v-model="vediRepAtt">
                  <label>Si &nbsp;</label>
                  <ion-radio value="1" :disabled="$store.state.userRole==0 || $store.state.userRole==2 ||  $store.state.userRole==3 ? true : false"></ion-radio>
                  <label>&nbsp; No &nbsp;</label>
                  <ion-radio value="2" :disabled="$store.state.userRole==0 || $store.state.userRole==2 ||  $store.state.userRole==3 ? true : false"></ion-radio>
                </ion-radio-group>
              </ion-col>
              <ion-col>
                <ion-radio-group v-model="vediRepPerm">
                  <label>Si &nbsp;</label>
                  <ion-radio value="1"></ion-radio>
                  <label>&nbsp; No &nbsp;</label>
                  <ion-radio value="2"></ion-radio>
                </ion-radio-group>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                  <label><b>Report contratti</b></label>
              </ion-col>
              <ion-col>
                  <label><b>Registraz. attività</b></label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-radio-group v-model="vediRepCon">
                  <label>Si &nbsp;</label>
                  <ion-radio value="1" :disabled="$store.state.userRol==0 || $store.state.userRole==2 ||  $store.state.userRole==3"></ion-radio>
                  <label>&nbsp; No &nbsp;</label>
                  <ion-radio value="2" :disabled="$store.state.userRole==0 || $store.state.userRole==2 ||  $store.state.userRole==3"></ion-radio>
                </ion-radio-group>
              </ion-col>
              <ion-col>
                <ion-radio-group v-model="vediRegAtt">
                  <label>Si &nbsp;</label>
                  <ion-radio value="1" :disabled="$store.state.userRole==0 || $store.state.userRole==2 ||  $store.state.userRole==3"></ion-radio>
                  <label>&nbsp; No &nbsp;</label>
                  <ion-radio value="2" :disabled="$store.state.userRole==0 || $store.state.userRole==2 ||  $store.state.userRole==3"></ion-radio>
                </ion-radio-group>
              </ion-col>
            </ion-row>
            <ion-button id="aggiornapermbtn" @click="modificaDashboard">Modifica</ion-button>
          </div>
           </ion-grid>
        </div>
      </ion-content>
    </ion-page>
</template>

<script>
  import { 
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonInput,
    //IonItem,
    IonLabel,
    IonMenuButton,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonTitle,
    IonToolbar,
    toastController
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonButton,
      IonButtons,
      IonCol,
      IonContent,
      IonGrid,
      IonHeader,
      IonInput,
      //IonItem,
      IonLabel,
      IonMenuButton,
      IonPage,
      IonRadio,
      IonRadioGroup,
      IonRow,
      IonTitle,
      IonToolbar
    },
    data: function(){
      return{
        isHidden:false,
        user:{
          name:'',
          email:'',
          password:''
        },
        nuovapwd:'',
        confermapwd:'',
        vecchiapwd:'',
        verificapwd:'',
        vediBadge:'',
        vediRegAtt:'',
        vediRepCon:'',
        vediRepPerm:'',
        vediRepAtt:'',
        vediProgAtt:'',
      }
    },
    methods:{
      async modificaPassword(){
        if(this.nuovapwd!=this.confermapwd || this.nuovapwd==''){
          const toast = await toastController
          .create({
            color: 'danger',
            message: 'Le password non corrispondono!',
            duration: 2000
          })
          return toast.present();
        }else{
          if(document.getElementById("nuovapwd").value.length>8){
            this.axios.post(this.apiUrl + 'update-password', {password:this.confermapwd}, this.headers)
            .then(async () => {
              this.nuovapwd='';
              this.confermapwd='';
              const toast = await toastController
              .create({
                color: 'success',
                message: 'Password aggiornata!',
                duration: 2000
              })
              return toast.present();
            })    
          }else{
            const toast = await toastController
            .create({
              color: 'danger',
              message: 'La password è troppo corta! (Minimo 8 caratteri)',
              duration: 2000
            })
            return toast.present();
          }        
        }
      },
      async modificaDashboard(){
            this.axios.post(this.apiUrl + 'update-dashboard', {vediBadge:this.vediBadge,vediRegAtt:this.vediRegAtt,vediRepCon:this.vediRepCon,vediRepPerm:this.vediRepPerm,vediRepAtt:this.vediRepAtt,vediProgAtt:this.vediProgAtt}, this.headers)
            .then(async () => {
              const toast = await toastController
              .create({
                color: 'success',
                message: 'Dashboard aggiornata!',
                duration: 2000
              })
              return toast.present();
            })    
      },
      getUserInfo(){
        this.axios.post(this.apiUrl + 'get-userInfo', {}, this.headers)
        .then(async (response) => {
         //console.log(response)
          this.user=response.data.user;

          this.vediBadge=response.data.user.vediBadge.toString();
          this.vediProgAtt=response.data.user.vediProg.toString();
          this.vediRegAtt=response.data.user.vediRegAtt.toString();
          this.vediRepAtt=response.data.user.vediRepAtt.toString();
          this.vediRepPerm=response.data.user.vediRepPerm.toString();
          this.vediRepCon=response.data.user.vediRepContr.toString();
          
          //console.log(this.vediBadge);
        })
      }
    },
    ionViewWillEnter: function(){
      this.getUserInfo();
    }
  }
</script>

<style scoped>
  #container {
    margin:5%;
    min-height:100%;
    text-align:center;
    display:flex;
    align-items:top;
    justify-content:center;
    flex-wrap:wrap;
  }
  #container strong {
    font-size: 20px;
    line-height: 26px;
  }
  #container p {
    font-size: 16px;
    line-height: 20px;
    color: #8c8c8c;
    margin: 0;
  }
  #container a {
    text-decoration: none;
  }
  #container ion-item{
    width: 100%;
    text-align: center;
    position: absolute;
    left: 15%;
    right: 0;
    top: -550%; 
    justify-content: center;
  }
  #inputpwd, #nuovapwd, #confermapwd{
    width:100%;
    height:100%;
    margin-left:0px;
  }
  ion-label{
    min-width:130px;
  }
  #rusername{
    border-top-right-radius: 10px;
  }
  #rpassword{
    border-bottom-right-radius: 10px;
  }
  #rvecchiapassword{
    margin-top:2%;
    border-top-right-radius: 10px;
  }
  #rconfermapassword{
    border-bottom-right-radius: 10px;
  }
  #husername,#hemail,#hpassword,#hnuovapassword,#hconfermapassword, #hvecchiapassword{
    border-radius:5px;
    max-width:100%;
    background: rgb(56,128,255);;
    font-weight: bold;
    color:white;
    text-align:center;
    border-right:2px solid white;
  }
  #remail, #rusername, #rpassword, #rnuovapassword, #rconfermapassword, #rvecchiapassword{
    margin-bottom:10px;
    margin-top:10px; 
  }
  ion-grid{
    margin-left:15px;
    width:90%;
    border-radius: 20px;
  }
  ion-row{
    max-width:50%;
  }
  #modpwd{
    width:150px;
    height: 50px;
  }
  #modificapwd{
    margin-bottom:-175px;
  }
  ion-button{
    width:100%;
  }
  #username, #email, #nuovapwd, #vecchiapwd, #confermapwd{
    border:1px solid gray;
    border-radius:5px;
  }
  #aggiornapwdbtn{
    margin-top:20px;
    margin-left:40%;
    width:150px;
    height: 50px;
  }

  #aggiornapermbtn{
    margin-top:20px;
    margin-right:50%;
    width:150px;
    height: 50px;
    }

  @media only screen and (max-width: 400px) {
    ion-row{
      max-width:100%;
    }
    #husername,#hemail,#hpassword,#hnuovapassword,#hconfermapassword{
      max-width:100%;
      font-weight: bold;
      color:white;
      text-align:center;
      border-right:2px solid white;
    }
    #aggiornapwdbtn{
      margin-left:25%;
    }
    #aggiornapermbtn{
      margin-left:25%;
    }
    ion-radio{
      border:2px solid grey;
      border-radius:60px;
    }
  }
</style>